import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  expertise: [],
  editExpertise: [],
  addExpertise: [],
  deleteExpertise: [],
};

const ExpertiseSlice = createSlice({
  name: "expertise",
  initialState,
  reducers: {
    ExpertiseRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.expertise = [];
    },
    ExpertiseSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.expertise = action.payload;
    },
    ExpertiseFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.expertise = [];
    },

    editExpertiseRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.editExpertise = [];
    },
    editExpertiseSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.editExpertise = action.payload;
    },
    editExpertiseFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.editExpertise = [];
    },

    addExpertiseRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.addExpertise = [];
    },
    addExpertiseSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.addExpertise = action.payload;
    },
    addExpertiseFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.addExpertise = [];
    },

    deleteExpertiseRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.deleteExpertise = [];
    },
    deleteExpertiseSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.deleteExpertise = action.payload;
    },
    deleteExpertiseFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.deleteExpertise = [];
    },
  },
});

export const ExpertiseAction = ExpertiseSlice.actions;
export default ExpertiseSlice;
