import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  statistics: [],
};

const StatisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    StatisticsRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.statistics = [];
    },
    StatisticsSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.statistics = action.payload;
    },
    StatisticsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.statistics = [];
    },
  },
});

export const StatisticsAction = StatisticsSlice.actions;
export default StatisticsSlice;
