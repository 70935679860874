import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  fellow: [],
  editFellowww: [],
  deleteFellowCv: [],
};

const FellowSlice = createSlice({
  name: "fellow",
  initialState,
  reducers: {
    FellowRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.fellow = [];
    },
    FellowSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.fellow = action.payload;
      state.editFellowww = [];
    },
    FellowFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.fellow = [];
    },

    editFellowRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.editFellowww = [];
    },
    editFellowSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.editFellowww = action.payload;
    },
    editFellowFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.editFellowww = [];
    },

    deleteFellowCvRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.deleteFellowCv = [];
    },
    deleteFellowCvSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.deleteFellowCv = action.payload;
    },
    deleteFellowCvFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.deleteFellowCv = [];
    },
  },
});

export const FellowAction = FellowSlice.actions;
export default FellowSlice;
