import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  fieldOfInterest: [],
};

const FieldOfInterestSlice = createSlice({
  name: "fieldOfInterest",
  initialState,
  reducers: {
    FieldOfInterestRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.fieldOfInterest = [];
    },
    FieldOfInterestSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.fieldOfInterest = action.payload;
    },
    FieldOfInterestFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.fieldOfInterest = [];
    },
  },
});

export const FieldOfInterestAction = FieldOfInterestSlice.actions;
export default FieldOfInterestSlice;
