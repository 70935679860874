import { combineReducers } from "redux";

import AuthSlice from "./auth/authReducer";
import CsoSlice from "./CSO/csoReducer";
import FellowSlice from "./Fellow/fellowReducer";
import AdminSlice from "./Admin/adminReducer";
import ExpertiseSlice from "./Expertise/expertiseReducer";
import CountrySlice from "./Country/countryReducer";
import StatisticsSlice from "./Statistics/statisticsReducer";
import FieldOfInterestSlice from "./FieldOfInterest/fieldOfInterestReducer";

const rootreducer = combineReducers({
  AuthReducer: AuthSlice.reducer,
  CsoReducer: CsoSlice.reducer,
  FellowReducer: FellowSlice.reducer,
  AdminReducer: AdminSlice.reducer,
  ExpertiseReducer: ExpertiseSlice.reducer,
  CountryReducer: CountrySlice.reducer,
  StatisticsReducer: StatisticsSlice.reducer,
  FieldOfInterestReducer: FieldOfInterestSlice.reducer,
});

export default rootreducer;
