import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  CSO: [],
  editCSOOO: [],
  connectCSO: [],
};

const CsoSlice = createSlice({
  name: "CSO",
  initialState,
  reducers: {
    CsoRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.CSO = [];
    },
    CsoSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.CSO = action.payload;
      state.editCSOOO = [];
    },
    CsoFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.CSO = [];
    },

    editCsoRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.editCSOOO = [];
    },
    editCsoSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.editCSOOO = action.payload;
    },
    editCsoFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.editCSOOO = [];
    },

    connectCsoRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.connectCSO = [];
    },
    connectCsoSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.connectCSO = action.payload;
    },
    connectCsoFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.connectCSO = [];
    },
  },
});

export const CsoAction = CsoSlice.actions;
export default CsoSlice;
