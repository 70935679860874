import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  admin: [],
  addAdminnnn: [],
  editAdmin: [],
  deleteAdmin: [],
};

const AdminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    AdminRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.admin = [];
    },
    AdminSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.admin = action.payload;
    },
    AdminFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.admin = [];
    },

    addAdminRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.addAdminnnn = [];
    },
    addAdminSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.addAdminnnn = action.payload;
    },
    addAdminFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.addAdminnnn = [];
    },

    editAdminRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.editAdmin = [];
    },
    editAdminSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.editAdmin = action.payload;
    },
    editAdminFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.editAdmin = [];
    },

    deleteAdminRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.deleteAdmin = [];
    },
    deleteAdminSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.deleteAdmin = action.payload;
    },
    deleteAdminFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.deleteAdmin = [];
    },
  },
});

export const AdminAction = AdminSlice.actions;
export default AdminSlice;
