import { lazy } from "react";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));

/***** Pages ****/
const Login = lazy(() => import("../components/Pages/Auth/Login.js"));
const Verify = lazy(() => import("../components/Pages/Auth/Verify.js"));
const Dashboard = lazy(() =>
  import("../components/Pages/Dashboard/Dashboard.js")
);
const Admin = lazy(() => import("../components/Pages/Admin/Admin.js"));
const CSO = lazy(() => import("../components/Pages/CSO/CSO.js"));
const Fellow = lazy(() => import("../components/Pages/Fellow/Fellow.js"));
const Expertise = lazy(() =>
  import("../components/Pages/Expertise/Expertise.js")
);
const Country = lazy(() => import("../components/Pages/Country/Country.js"));

/*****Routes******/

const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", exact: true, element: <Dashboard /> },
      { path: "/admins", exact: true, element: <Admin /> },
      { path: "/csos", exact: true, element: <CSO /> },
      { path: "/fellows", exact: true, element: <Fellow /> },
      { path: "/expertise", exact: true, element: <Expertise /> },
      { path: "/country", exact: true, element: <Country /> },
    ],
  },
  {
    path: "/",
    children: [
      { path: "/login", exact: true, element: <Login /> },
      { path: "/verify", exact: true, element: <Verify /> },
    ],
  },
];

export default ThemeRoutes;
